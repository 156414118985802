export const useAnalytics = () => {
  return {
    freeSecurityTestClicked: () => {
      amplitudeEvent("CLICK_GET_FREE_SECURITY_TEST")
    },
    tryNowClicked: () => {
      amplitudeEvent("CLICK_TRY_NOW")
    },
    clickSignIn: () => {
      amplitudeEvent("CLICK_SIGN_IN")
    },
    clickGoToDashboard: () => {
      amplitudeEvent("CLICK_GOTO_DASHBOARD")
    },
    showService: SERVICE => {
      amplitudeEvent("SHOW_SERVICE", { SERVICE })
    },
    freeTestClicked: () => {
      amplitudeEvent("CLICK_FREE_TEST")
    },
    tryForFreeClicked: () => {
      amplitudeEvent("TRY_FOR_FREE")
    },
    showServices: () => {
      amplitudeEvent("SHOW_SERVICES")
    },
    clickGoToService: PAGE_NAME => {
      amplitudeEvent("CLICK_GOTO_SERVICE", { PAGE_NAME })
    },
    clickGoToTeam: () => {
      amplitudeEvent("CLICK_GOTO_TEAM")
    },
    clickGoToBlog: () => {
      amplitudeEvent("CLICK_GOTO_BLOG")
    },
    clickGoToPricing: () => {
      amplitudeEvent("CLICK_GOTO_PRICING")
    },
    clickGoToFAQ: () => {
      amplitudeEvent("CLICK_GOTO_FAQ")
    },
    clickGoToFB: () => {
      amplitudeEvent("CLICK_GOTO_FACEBOOK")
    },
    clickGoToLinkedIn: () => {
      amplitudeEvent("CLICK_GOTO_LINKEDIN")
    },
    clickGoToYT: () => {
      amplitudeEvent("CLICK_GOTO_YOUTUBE")
    },
    clickGoToPP: () => {
      amplitudeEvent("CLICK_GOTO_PRIVACY_POLICY")
    },
    clickGoToTerms: () => {
      amplitudeEvent("CLICK_GOTO_TERMS")
    },
    clickGoToContacts: () => {
      amplitudeEvent("CLICK_GOTO_CONTACTS")
    },
    clickGoToHowItWorks: () => {
      amplitudeEvent("CLICK_GOTO_HOW_IT_WORKS")
    },
    clickGoToAwareness: () => {
      amplitudeEvent("CLICK_GOTO_AWARENESS")
    },
    clickGoToLoadTesting: () => {
      amplitudeEvent("CLICK_GOTO_LOAD_TESTING")
    },
    clickGoToWordpressPlugin: () => {
      amplitudeEvent("CLICK_GOTO_WORDPRESS_PLUGIN")
    },
    clickGoToWordpressAudit: () => {
      amplitudeEvent("CLICK_GOTO_WORDPRESS_AUDIT")
    },
    clickGoToWordpressPentest: () => {
      amplitudeEvent("CLICK_GOTO_WORDPRESS_PENTEST")
    },
    clickStartForFree: () => {
      amplitudeEvent("CLICK_START_FOR_FREE")
    },
    clickGoToBlogMainPage: () => {
      amplitudeEvent("CLICK_GOTO_BLOG_MAIN_PAGE")
    },
    showProduct: () => {
      amplitudeEvent("SHOW_PRODUCT")
    },
    showCategory: () => {
      amplitudeEvent("SHOW_CATEGORY")
    },
    clickGoHome: () => {
      amplitudeEvent("CLICK_GO_HOME")
    },
    clickSignUp: () => {
      amplitudeEvent("CLICK_SIGN_UP")
    },
    clickOpenSearchQuery: () => {
      amplitudeEvent("CLICK_OPEN_SEARCH_QUERY")
    },
    clickSearchQuery: USER_INPUT => {
      amplitudeEvent("CLICK_SEARCH_QUERY", { USER_INPUT })
    },
    clickClearSearchQuery: () => {
      amplitudeEvent("CLICK_CLEAR_SEARCH_QUERY")
    },
    clickOpenSideMenu: () => {
      amplitudeEvent("CLICK_OPEN_SIDE_MENU")
    },
    clickCheckSite: () => {
      amplitudeEvent("CLICK_CHECK_SITE")
    },
    clickOpenPost: POST_TITLE => {
      amplitudeEvent("CLICK_OPEN_POST", { POST_TITLE })
    },
    clickSelectCategory: CATEGORY_NAME => {
      amplitudeEvent("CLICK_SELECT_CATEGORY", { CATEGORY_NAME })
    },
    clickLoadMore: () => {
      amplitudeEvent("CLICK_LOAD_MORE")
    },
    clickScrollTop: () => {
      amplitudeEvent("CLICK_SCROLL_TOP")
    },
    clickChangeCategory: CATEGORY_NAME => {
      amplitudeEvent("CLICK_CHANGE_CATEGORY", { CATEGORY_NAME })
    },
    clickGetStarted: () => {
      amplitudeEvent("CLICK_GET_STARTED")
    },
    clickSend: (
      INPUT_F_NAME,
      INPUT_L_NAME,
      INPUT_COMPANY,
      INPUT_EMAIL,
      INPUT_PHONE
    ) => {
      amplitudeEvent("CLICK_SEND", {
        INPUT_F_NAME,
        INPUT_L_NAME,
        INPUT_COMPANY,
        INPUT_EMAIL,
        INPUT_PHONE,
      })
    },
    clickChangeTab: TAB_NAME => {
      amplitudeEvent("CLICK_CHANGE_TAB", { TAB_NAME })
    },
    clickGetDemo: () => {
      amplitudeEvent("CLICK_GET_DEMO")
    },
    clickLetsChat: () => {
      amplitudeEvent("CLICK_LETS_CHAT")
    },
    signUp: (PERIOD, TARIFF) => {
      amplitudeEvent("SIGN_UP", { PERIOD, TARIFF })
    },
    INPUT_NAME: USER_INPUT => {
      amplitudeEvent("INPUT_NAME", { USER_INPUT })
    },
    INPUT_EMAIL: USER_INPUT => {
      amplitudeEvent("INPUT_EMAIL", { USER_INPUT })
    },
    next: (INPUT_NAME, INPUT_EMAIL, PROMOCODE) => {
      amplitudeEvent("NEXT", { INPUT_NAME, INPUT_EMAIL, PROMOCODE })
    },
    login: () => {
      amplitudeEvent("LOGIN")
    },
    getInTouch: () => {
      amplitudeEvent("GET_IN_TOUCH")
    },
    tryNow: () => {
      amplitudeEvent("TRY_NOW")
    },
    showAnswer: TITLE => {
      amplitudeEvent("SHOW_ANSWER", { TITLE })
    },
    downloadPlugin: () => {
      amplitudeEvent("DOWNLOAD_PLUGIN")
    },
    contactUs: () => {
      amplitudeEvent("CONTACT_US")
    },
    goNext: (INPUT_F_NAME, INPUT_L_NAME, INPUT_EMAIL, INPUT_DOMAIN) => {
      amplitudeEvent("GO_NEXT", {
        INPUT_F_NAME,
        INPUT_L_NAME,
        INPUT_EMAIL,
        INPUT_DOMAIN,
      })
    },
    inputFirstName: USER_INPUT => {
      amplitudeEvent("INPUT_FIRST_NAME", { USER_INPUT })
    },
    inputLastName: USER_INPUT => {
      amplitudeEvent("INPUT_LAST_NAME", { USER_INPUT })
    },
    inputCompanyName: USER_INPUT => {
      amplitudeEvent("INPUT_COMPANY_NAME", { USER_INPUT })
    },
    INPUT_DOMAIN: USER_INPUT => {
      amplitudeEvent("INPUT_DOMAIN", { USER_INPUT })
    },
    inputContactNumber: USER_INPUT => {
      amplitudeEvent("INPUT_CONTACT_NUMBER", { USER_INPUT })
    },
    inputMessage: USER_INPUT => {
      amplitudeEvent("INPUT_MESSAGE", { USER_INPUT })
    },
    confirmPolicy: FLAG => {
      amplitudeEvent("CONFIRM_POLICY", { FLAG })
    },
    sendMessage: (
      INPUT_F_NAME,
      INPUT_L_NAME,
      INPUT_EMAIL,
      INPUT_DOMAIN,
      INPUT_COMPANY_NAME,
      INPUT_CONTACT_NUMBER,
      INPUT_MESSAGE
    ) => {
      amplitudeEvent("SEND_MESSAGE", {
        INPUT_MESSAGE,
        INPUT_CONTACT_NUMBER,
        INPUT_COMPANY_NAME,
        INPUT_DOMAIN,
        INPUT_EMAIL,
        INPUT_L_NAME,
        INPUT_F_NAME,
      })
    },
    getTheReport: (INPUT_EMAIL, INPUT_DOMAIN, FLAG_CONFIRM) => {
      amplitudeEvent("CLICK_GET_REPORT", {
        INPUT_EMAIL,
        INPUT_DOMAIN,
        FLAG_CONFIRM,
      })
    },
    seeAllPosts: () => {
      amplitudeEvent("CLICK_SEE_ALL_POSTS")
    },
    viewAllFeatures: () => {
      amplitudeEvent("CLICK_VIEW_ALL_FEATURES")
    },
    changePlans: PLAN_TYPE => {
      amplitudeEvent("CLICK_CHANGE_PLANS", { PLAN_TYPE })
    },
    changePlanPeriod: PERIOD => {
      amplitudeEvent("CHANGE_PLAN_PERIOD", { PERIOD })
    },
    clickAllowCookies: () => {
      amplitudeEvent("CLICK_ALLOW_COOKIES")
    },
    changeTab: ABOUT_SERVICE => {
      amplitudeEvent("CLICK_CHANGE_TAB", { ABOUT_SERVICE })
    },
    changeInfo: SERVICE => {
      amplitudeEvent("CLICK_CHANGE_INFO", { SERVICE })
    },
    bugBounty: () => {
      amplitudeEvent("CLICK_GET_IN_TOUCH_BUGBOUNTY")
    },
  }
}

export const amplitudeEvent = (event, props) => {
  if (typeof window !== undefined) {
    import("amplitude-js").then(amplitude => {
      const identify = new amplitude.Identify().set(
        "LAST_SEEN_DATE",
        new Date().toISOString()
      )
      amplitude.getInstance().identify(identify)

      const additionalProps = { WT_PLATFORM: "website" }
      const resultProps = props
        ? Object.assign(props, additionalProps)
        : additionalProps

      amplitude.getInstance().logEvent(event, resultProps)
    })
  }
}
